@import "../../styles/__imports.scss";

.tabs {
    width: 100%;

    &__nav {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav {
            &__item {
                // @include clearButton();
                width: max-content;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__container {
        width: 100%;
        .container {
            &__tab {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0;
                transition: opacity 0.6s ease-out;

                &.active {
                    position: relative;
                    z-index: 10;
                    opacity: 1;
                }
            }
        }
    }
}
