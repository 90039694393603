@import "../../styles/_imports";

.button {
    &__adhock {
        @include clearButton();
        border: 1px solid $color__omega;
        background-color: #f0f0f0;
    }

    &__free {
        @include clearButton();
        background-color: $color__beta;
        border: 1px solid $color__omega;
        color: $color__omega !important;
    }
    
    &__visit {
        @include clearButton();
        background-color: $color__alfa-alt;
        border: 1px solid $color__omega;
        color: $color__omega !important;
        
        &.confirmed {
            background-color: $color__alfa;
            font-weight: 600;
        }
        
        i {
            position: absolute;
            top: 4px;
            right: 4px;
            color: $color__omega;
            text-shadow: 0 0 6px rgba($color: $color__omega, $alpha: 0.5);
        }
        
        &.button__done {
        @include clearButton();
        background-color: $color__gamma;
        border: 1px solid $color__omega;
    }
    }
    
    
}
