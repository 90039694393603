.day {
    width: 15.6%;
    // border: 1px solid #d2d2d2;

    &__date {
        text-align: center;
        // border-bottom: 1px solid #d2d2d2;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 600;
    }

    &__list {
        position: relative;

        .list {
            &__item {
                position: absolute;
                left: 0;
                width: 100%;
            }
        }
    }
}
