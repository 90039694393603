// @import "../Abstracts/AbstractMixins.scss";
@import "../_imports";

.popup {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translate(-50%);
}

body,
html {
    background-color: $color__bg--alfa;
}

.container-fluid,
.row,
.col {
    background-color: $color__bg--alfa;
}

.card-header {
    background: $color-gradient__alfa !important;
    color: $color__omega;
    font-weight: 500 !important;
}

.over__clendar {
    z-index: 20;
}

input,
select {
    font-size: 16px !important;
    color: $color__text !important;
    height: 36px !important;
}

input[type="radio"] {
    height: 1em !important;
}

.form-gorup,
.row,
.col,
.col-sm-12 {
    background-color: $color__omega !important;
}

.card {
    padding: 0 !important;
}

.required {
    &::after {
        content: " *";
        color: inherit;
    }
}

.btn {
    &-primary {
        background-color: $color__alfa;
        border-color: $color__alfa;

        &:hover,
        &:focus,
        &:active {
            background-color: $color__alfa-alt;
        }
    }

    &-outline-primary {
        background-color: transparent;
        border-color: $color__alfa;
        color: $color__alfa;

        &:hover,
        &:focus,
        &:active {
            background-color: $color__alfa;
        }
    }
}

.weight {
    &__semi-bold {
        font-weight: 600;
    }
}
