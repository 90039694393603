@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400&display=swap");

@import './helpers//HelperCommon.scss';

body {
    font-family: "Roboto";
}

.card {
    box-shadow: 2px 2px 10px #c2c2c2;
}

