@import '../../styles/_imports';

.profile {
  &__data {
    .data {
      &__item {
        display: flex;
        justify-content: center;

        .item {
          &__name {
            width: 50%;
            padding-right: 10px;
            text-align: end;
            color: $color__beta;
            margin: 8px 0;
          }

          &__value {
              width: 50%;
            padding-left: 20px;
            margin: 8px 0;
          }
        }
      }
    }
  }
}
