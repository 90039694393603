$color__alfa: #0b3f8b; //#2e1228;
$color__alfa-alt: #33bac0; //#785774;
$color__beta: rgb(109, 109, 109);
$color__gamma: rgb(27, 27, 27);
$color__delta: #e6e6e6;

$color__psi: #000;
$color__omega: #fff;

$color__important: #c82000;

$color__bg--alfa: #f3f3f3;

$color__shadow: #292929;
$color__text: #0f0f0f;

$color-gradient__alfa: linear-gradient(150deg, $color__alfa 40%, $color__alfa-alt 100%);

// /* SCSS HEX */
// $dark-purple: #2e1228ff;
// $chinese-violet: #785774ff;
// $raisin-black: #2b212aff;
// $languid-lavender: #d6c5d4ff;
// $platinum: #e6e6e6ff;

// /* SCSS HSL */
// $dark-purple: hsla(313, 44%, 13%, 1);
// $chinese-violet: hsla(307, 16%, 41%, 1);
// $raisin-black: hsla(306, 13%, 15%, 1);
// $languid-lavender: hsla(307, 17%, 81%, 1);
// $platinum: hsla(0, 0%, 90%, 1);

// /* SCSS RGB */
// $dark-purple: rgba(46, 18, 40, 1);
// $chinese-violet: rgba(120, 87, 116, 1);
// $raisin-black: rgba(43, 33, 42, 1);
// $languid-lavender: rgba(214, 197, 212, 1);
// $platinum: rgba(230, 230, 230, 1);

// /* SCSS Gradient */
// $gradient-top: linear-gradient(0deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-right: linear-gradient(90deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-bottom: linear-gradient(180deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-left: linear-gradient(270deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-top-right: linear-gradient(45deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-bottom-right: linear-gradient(135deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-top-left: linear-gradient(225deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-bottom-left: linear-gradient(315deg, #2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
// $gradient-radial: radial-gradient(#2e1228ff, #785774ff, #2b212aff, #d6c5d4ff, #e6e6e6ff);
