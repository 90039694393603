@import "../../styles/__imports.scss";

.statusbar {
    padding: 10px 10px;
    width: calc(100% - 60px);
    position: fixed;
    top: 0;
    right: 0;
    background-color: $color__bg--alfa;
    box-shadow: 2px 2px 5px $color__shadow;
    z-index: 300;

    &__message {
        color: $color__omega;
        font-size: 18px;
        font-weight: 400;
        margin-right: 25px;

        .message {
            &__show {
                padding: 10px 6px;
                border: 1px solid $color__alfa;
                border-radius: 5px;
                transition: opacity 0.3s ease-out;
                background-color: $color__alfa-alt;

                &.hidden {
                    opacity: 0;
                }
            }
        }
    }

    &__user {
        @include clearButton;
        position: relative;
        background: $color-gradient__alfa;
        color: $color__delta;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin: 0 20px;
        font-weight: 600;
        font-size: 20px;
        transition: transform 0.3s ease-out;

        &:hover {
            transform: scale(1.1);
        }

        &:focus,
        &:active,
        &:focus-within {
            transform: scale(1.1);

            .user__nav {
                visibility: visible;
                opacity: 1;
            }
        }

        .user {
            &__nav {
                position: absolute;
                top: calc(100% + 15px);
                right: 0;
                background: $color-gradient__alfa;
                border-radius: 15px;
                padding: 15px 20px;
                width: max-content;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s ease-out;
                display: flex;
                flex-direction: column;
                cursor: default;

                button,
                a {
                    @include clearButton();
                    font-size: 16px;
                    padding: 8px 0;
                    font-weight: 400;
                    color: $color__omega;
                    transition: all 0.3s ease-out;

                    &:hover,
                    &:focus,
                    &:active {
                        text-shadow: 1px 0 0 $color__omega;
                    }
                }
            }

            &__info {
                font-size: 14px;
                padding-bottom: 12px;
                font-weight: 400;
            }
        }
    }

    &__notification {
        @include clearButton;
        position: relative;
        font-size: 35px;
        background: $color-gradient__alfa;
        background-clip: text;
        color: transparent;
        transition: all 0.3s ease-out;

        &:hover,
        &:focus,
        &:active,
        &:focus-within {
            text-shadow: 0 0 30px rgba($color: $color__omega, $alpha: 1);

            .notification__box {
                opacity: 1;
                visibility: visible;
            }
        }

        .notification {
            &__box {
                font-size: 14px;
                font-weight: 300;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 350;
                transform: translate(0, 100%);
                background-color: $color__bg--alfa;
                box-shadow: 2px 2px 5px $color__shadow;
                border-radius: 20px;
                width: max-content;
                max-width: 90vw;
                max-height: calc(100vh - 70px);
                overflow: auto;
                color: $color__text;
                padding: 20px 20px;
                text-align: left;
                opacity: 0;
                visibility: hidden;
                cursor: default;
                transition: opacity 0.3s ease-out;

                .box {
                    &__item {
                        padding: 6px 0;

                        .item {
                            &__time {
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
