@import "../../styles/__imports.scss";

.add-visit {
    .loading {
        pointer-events: none;

        &::after {
            content: "";
            width: 100%;
            height: 100% !important;
            background-color: #00000090;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 50;
        }

        &::before {
            content: "";
            width: 40px;
            height: 40px;
            border: 3px solid $color__alfa;
            border-radius: 50%;
            border-left: 3px solid transparent;
            z-index: 51;
            position: absolute;
            left: 50%;
            top: 50% !important;
            animation: spin 0.5s linear infinite;
        }
    }
}
