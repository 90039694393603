@import "../../styles/__imports.scss";

.addfile {
    label {
        padding: 10px 20px;
        border: 2px solid $color__alfa;
        border-radius: 10px;
        background-color: $color__alfa-alt;
        color: $color__omega;
        display: flex;
        align-items: center;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.2s ease-out;
        flex-wrap: wrap;
        position: relative;

        &:hover,
        &:focus,
        &:active {
            background-color: $color__alfa;
        }

        i {
            font-size: 32px;
            margin-right: 15px;
            line-height: 1em;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -10000;
            opacity: 0;
        }
    }

    button {
        @include clearButton();
        padding: 10px 20px;
        border: 2px solid $color__alfa;
        border-radius: 10px;
        color: $color__alfa;
        background-color: $color__omega;
        display: flex;
        align-items: center;
        font-size: 20px;
        transition: all 0.2s ease-out;
        margin-left: 50px;

        i {
            font-size: 32px;
            margin-right: 15px;
            line-height: 1em;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color__alfa;
            color: $color__omega;
        }
    }

    span {
        width: 100%;
        font-size: 12px;
    }
}
