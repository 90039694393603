@import "../../styles/_imports";

.calendar {
    width: 100%;
    display: flex;
    flex-direction: row;
        
    
    &.single {
        min-width: 230px;
        padding: 0 5px;
        
        .day {
            width: 100%;
        }
        
        .calendar__hours {
            width: 45px
        }
    }

    &__hours {
        width: 6%;
        position: relative;
        margin-top: 80px;

        .hours {
            &__item {
                position: absolute; 
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                border-top: 2px solid #d2d2d2;

                .item {
                    &__hour {
                        font-size: 18px;
                        display: inline-block; 
                    }

                    &__minutes {
                        font-size: 12px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
