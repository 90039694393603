.text {
}

.color {
    &__alfa {
        color: $color__alfa;
    }

    &__omega {
        color: $color__omega !important;
    }

    &__important {
        color: $color__important !important;
    }
}

.weight {
    &__bold {
        font-weight: 700;
    }
}
