@import "../../styles/_imports";

.pro-navbar {
    height: 100vh;
    width: 360px;
    border: 1px solid rgba($color: $color__beta, $alpha: 0.2);
    background: $color-gradient__alfa;
    box-shadow: 2px 2px 10px #c2c2c2;
    padding: 10px; // 25px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    transition: all 0.4s ease-out;
    z-index: 500;

    &.hidden {
        transform: translate(calc(-100% + 65px));
    }

    .navbar {
        &__toggle-button {
            @include clearButton();
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: transform 0.5s ease-out;

            .toggle-button {
                &__icon {
                    // font-size: 40px;
                    font-size: 30px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__list {
            list-style: none;
            padding: 0;

            .list {
                &__item {
                    text-decoration: none;

                    .item {
                        &__button {
                            @include clearButton();
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            opacity: 0.6;
                            transition: opacity 0.3s ease-out;
                            // margin-top: 20px;
                            margin-top: 10px;

                            &.active {
                                opacity: 1;

                                .button {
                                    &__icon {
                                        text-shadow: 0 0 30px rgba($color: $color__omega, $alpha: 0.5);
                                    }

                                    &__text {
                                        text-shadow: 1px 0 0 $color__omega;
                                    }
                                }
                            }

                            .button {
                                &__text {
                                    font-weight: 500;
                                }

                                &__icon {
                                    // font-size: 40px;
                                    font-size: 30px;
                                    margin-left: 25px;
                                    width: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: all 0.3s ease-out;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__calendar {
            width: 100%;
            transition: opacity 0.4s ease-out;
            
            .fc-daygrid-day-events, .fc-daygrid-day-bg {
                display: none;
            }
        }
    }
}
