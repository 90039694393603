@import "../../styles/__imports.scss";

.arrow-button {
    @include clearButton();
    font-size: 40px;
    margin-bottom: -70px;
    cursor: pointer;
    background: $color-gradient__alfa;
    background-clip: text;
    color: transparent;
    position: relative;
    transition: all 0.3s ease-out;

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.2);
        opacity: 0.9;
    }
}

.loading {
    pointer-events: none;

    &::after {
        content: "";
        width: 100%;
        height: 2400px;
        background-color: #00000090;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 50;
    }

    &::before {
        content: "";
        width: 40px;
        height: 40px;
        border: 3px solid $color__alfa;
        border-radius: 50%;
        border-left: 3px solid transparent;
        z-index: 51;
        position: absolute;
        left: 50%;
        top: 50vh;
        animation: spin 0.5s linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
