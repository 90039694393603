.login {
    width: 100vw;
    height: 100vh;
    
    &__form {
        width: 350px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}