@import "../../styles/__imports.scss";

.human {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%);
    width: auto;
    height: 90vh;
    z-index: 500;
    box-shadow: 5px 5px 10px $color__shadow;

    &__wrapper {
        width: auto;
        height: 100%;
        position: relative;

        .wrapper {
            &__image {
                width: auto;
                height: 100%;
                position: relative;
                user-select: none;
                -webkit-user-drag: none;
            }

            &__button {
                @include clearButton();
                position: absolute;
                width: 25px;
                height: 25px;
                transform: translate(-50%, -50%);
                border-radius: 100%;
                background: $color-gradient__alfa;
                z-index: 550;
            }
        }
    }
}
