html {
    color: $color__gamma;
}

ul,
li,
ol {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 0;
}
