@import "../../styles/_imports";

.fc {
    &-toolbar-title {
        color: $color__omega;
        font-size: 20px !important;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &-button {
        background: transparent !important;
        border: none !important;
        outline: none !important;

        &:hover,
        &:focus,
        &:active {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    &-col-header-cell-cushion {
        text-decoration: none;
        color: $color__omega;
    }

    &-daygrid-day-top {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-daygrid-day-number {
        text-decoration: none;
        color: $color__omega;
        // display: block;
        // height: 0;
        // width: 30px;
        font-size: 14px;
        text-align: center;
        padding: 0;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none !important;
            color: $color__beta;
            cursor: pointer;
        }
    }

    &-scroller {
        overflow: visible !important;
    }

    &-scrollgrid {
        display: block;
        width: 100%;
        border: none !important;

        * {
            border: none !important;
        }

        tbody {
            display: flex;
            flex-direction: column;
            width: 100% !important;
            height: 100% !important;

            tr {
                display: block;
                width: 100%;
                height: 100% !important;

                td {
                    display: block;
                    width: 100%;
                    height: 100% !important;

                    div {
                        width: 100%;
                        height: 100% !important;

                        div {
                            width: 100%;
                            height: 100% !important;

                            table {
                                display: flex;
                                width: 100% !important;
                                height: 100% !important;

                                tbody {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100% !important;

                                    tr {
                                        display: flex;
                                        width: 100%;
                                        height: auto !important;

                                        th,
                                        td {
                                            width: max-content;
                                            display: flex;
                                            height: 35px !important;
                                            width: (100% / 7);
                                            border: none;
                                        }
                                    }
                                }
                            }

                            div {
                                width: 100%;
                                height: 100% !important;

                                table {
                                    display: flex;
                                    width: 100% !important;
                                    height: 100% !important;

                                    tbody {
                                        display: flex;
                                        flex-direction: column;
                                        width: 100% !important;
                                        height: 100% !important;

                                        tr {
                                            display: flex;
                                            width: 100%;
                                            height: auto !important;

                                            th,
                                            td {
                                                width: max-content;
                                                display: flex;
                                                width: (100% / 7);
                                                justify-content: center;
                                                align-items: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
